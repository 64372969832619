export default [
  {
    path: "/admin",
    component: () => import("@/apps/admin/views/Admin"),
    redirect: { name: "admin" },
    meta: {
      title: "Админ панель",
    },
    children: [
      {
        path: "users",
        name: "admin",
        component: () => import("@/apps/admin/views/Users"),
      },
      {
        path: "groups",
        name: "adminGroups",
        component: () => import("@/apps/admin/views/Groups"),
      },
    ],
  },
];
