export default [
  {
    path: "/loginbyjwttoken/:id",
    props: true,
    name: "loginJWTToken",
    component: () => import("@/apps/login-jwt-token/views/LoginJWTToken"),
    meta: {
      title: "Вход по JWT токену",
    },
  },
];
