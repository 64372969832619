<template>
  <router-view />
</template>

<style lang="scss" scoped>
.app-wrapper {
  width: 100%;
  height: 100vh;
  background: var(--bg);
}
</style>
