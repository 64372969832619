export default [
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/apps/profile/views/Profile"),
    meta: {
      title: "Профиль",
    },
  },
  {
    path: "/profile_token",
    name: "profile_token",
    component: () => import("@/apps/profile/views/ProfileToken"),
    meta: {
      title: "Смена Пан кода",
    },
  },
];
