import registration from "@/apps/registration/routes";
import login from "@/apps/login/routes";
import external from "@/apps/external/routes";
import loginToken from "@/apps/login-token/routes";
import loginJWTToken from "@/apps/login-jwt-token/routes";
import profile from "@/apps/profile/routes";
import admin from "@/apps/admin/routes";
import restoration from "@/apps/restoration/routes";
import sms from "@/apps/sms/routes";

export default [
  ...registration,
  ...login,
  ...external,
  ...loginToken,
  ...loginJWTToken,
  ...profile,
  ...admin,
  ...restoration,
  ...sms,
  {
    path: "/:catchAll(.*)",
    redirect: { path: "/login" },
  },
];
