const state = {
  globalState: "globalState",
  globalSocket: null,
};

const getters = {
  globalState: (state) => state.globalState,
  globalSocket: (state) => state.globalSocket,
};

const actions = {};

const mutations = {
  setGlobalState: (state, payload) => {
    state.globalState = payload;
  },
  setGlobalSocket: (state, socket) => {
    state.globalSocket = socket;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
