import "@/core/styles/main.scss";

import VueNumberInput from "@chenfengyuan/vue-number-input";
import { createApp } from "vue";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

// import * as login from "@/apps/login";
import App from "./App.vue";
import router from "./router";
import store from "./stores";

(async () => {
  const app = createApp(App);
  app.component(VueNumberInput.name, VueNumberInput);

  if (process.env.VUE_APP_SSO_FRONTEND_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.VUE_APP_SSO_FRONTEND_SENTRY_DSN,
      integrations: [
        new VueIntegration({
          app,
          tracing: true,
        }),
        new Integrations.BrowserTracing(),
      ],
      tracesSampleRate: 1,
    });
  }
  // await login.connect();

  app.use(router);
  app.use(store);

  app.mount("#app");
})();
